.app__about {
  background-color: white;
  padding: 1.5rem 1.5rem;

  .app__about-section {
    text-align: center;

    h1 {
      margin-top: 2.5rem;
      margin-bottom: 1.2rem;
    }
  }

  .app__about-organizers {
    .app__about-organizers_list {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2.5rem;

      .organizer__card {
        img {
          width: 12rem;
        }
      }
    }
  }

  .app__about-speakers {
    .app__about-speakers_list {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2.5rem;

      .speaker__card {
        img {
          width: 12rem;
        }
      }
    }
  }
}
