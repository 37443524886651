.sub-theme {
  margin-top: 20px;
}

.sub-theme h2 {
  margin-bottom: 20px;
  text-align: center;
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  font-weight: 500;
  font-size: 48px;
}

.events {
  /* margin: 0 auto; */
  display: grid;
  gap: 20px 10px;
  margin: 0 auto;
  padding: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto auto auto;
}
.event-card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding-bottom: 25px;
}

.event-card img {
  width: 100%;
  object-fit: cover;
  height: 80%;
}
.event-title {
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  color: #fff;
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  font-size: 22px;
  text-align: center;
}

.event-container p {
  text-align: center;
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.event-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .sub-theme h2 {
    font-size: 35px;
  }

  .events {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }

  .event-container {
    padding: 5px;
  }

  .event-container p {
    font-size: 16px;
  }
}

@media (max-width: 720px) {
  .sub-theme h2 {
    font-size: 30px;
  }
  .events {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
  }

  .event-container p {
    text-align: center;
    font-size: 15px;
  }
}

@media (max-width: 500px) {
  .events {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .event-container p {
    font-size: 15px;
  }
}

@media (max-width: 250px) {
  .events {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-columns: 1fr;
  }
}
