.CountDownTimer {
  display: grid;
  width: 60%;
  grid-template-columns: repeat(4, 1fr);
  margin: -4em auto 0;

  @media (max-width: 850px) {
    margin-top: -1.3em;
    padding-top: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 550px) {
    margin-top: -2em;
    width: 85%;
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 450px) {
    padding-top: 40px;
    gap: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 375px) {
    width: 90%;
    gap: 10px;
  }
}

.TimeCard {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 130px;
  border-radius: 4px;
  font-weight: 600;
  // background: linear-gradient(90deg, #FDBB2D 0%, #3A1C71 100%);
  background-color: #000000;

  h2 {
    font-weight: 700;
    font-size: 2.5em;
    margin: 0;
  }

  span {
    text-transform: uppercase;
  }

  @media (max-width: 960px) {
    width: 120px;
    height: 120px;
  }

  @media (max-width: 768px) {
    width: 95px;
    height: 95px;

    h2 {
      font-size: 2em;
    }

    span {
      font-size: 0.75em;

      @media (max-width: 320px) {
        font-size: 0.6em;
      }
    }
  }

  @media (max-width: 450px) {
    width: 75px;
    height: 75px;
  }

  @media (max-width: 375px) {
    width: 55px;
    height: 55px;
  }
}
