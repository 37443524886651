@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;800&display=swap");

* {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --font-base: "Open Sans", sans-serif;
  --font-alt: "Julius Sans One", sans-serif;

  --color-black: #000000;
  --light-gray: #7c7c7c;
  --dark-gray: #333f52;
  --color-white: #ffffff;
  --light-blue: #63a3fd;
  --dark-blue: #205ef1;
  --color-green: #41d895;
}
