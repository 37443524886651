.OrderOfProgram {
  width: 80%;
  margin: 2em auto;

  .Dropdown {
    cursor: pointer;
    padding: 1.2em 0;
    border-bottom: 1px solid rgb(173, 173, 173);

    &:nth-of-type(1) {
      border-top: 1px solid rgb(173, 173, 173);

      .DropdownHeader {
        h2 {
          margin-bottom: 0;
        }
      }
    }

    &:nth-last-of-type(1) {
      border-bottom: 0;
      margin-bottom: 7em;
    }

    &Header {
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        display: block;
      }

      h2 {
        font-weight: 400;
        font-size: 1em;
        padding: 7px;
        border: 1px solid black;
        width: 15em;
        border-radius: 25px;
        text-align: center;
        margin-bottom: 2em;

        &:nth-of-type(2) {
          width: auto;
          border: none;
          font-weight: bold;
          text-transform: uppercase;
          margin-left: 2em;

          @media screen and (max-width: 768px) {
            margin: 0;
            text-align: left;
            margin-top: 1em;
          }
        }
      }
    }

    ul {
      padding-inline-start: 0;
      margin-left: 4em;
      font-weight: 400;
      line-height: 1.75em;
      list-style-type: circle;

      @media screen and (max-width: 768px) {
        margin-left: 2em;
      }

      ul {
        list-style-type: square;
      }
    }
  }
}
