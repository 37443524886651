.Footer {
  padding: 4em 0;
  background-image: url("./../../assets/heroImg.png");

  color: #ffffff;
  background-size: cover;
  background-position: center;
  background-color: rgba(50, 50, 50, 0.458);
  background-blend-mode: multiply;

  &Data {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1.2fr;
    margin: auto;
    width: 90%;
    max-width: 1440px;

    div {
      img {
        width: 10em;
      }

      font-size: 0.9em;
      h2,
      ul,
      p {
        margin: 0;
      }

      h2 {
        font-size: 1.25em;
        font-weight: 600;
      }

      ul {
        list-style-type: none;
        padding-inline-start: 0;

        li {
          margin: 0.75em 0;

          a {
            color: #ffffff;
            text-decoration: none;
          }
        }
      }

      &:nth-of-type(1) {
        p {
          width: 80%;
          margin-top: 2em;
          line-height: 1.25;
        }
      }

      .FooterIcon {
        margin: 0.75em;

        &:nth-of-type(1) {
          margin-left: 0;
        }
      }
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
      gap: 2em 0em;

      div {
        &:nth-of-type(1) {
          grid-column: 1/3;
          text-align: center;

          p {
            width: 100%;
          }
        }
      }
    }
  }

  &CopyRight {
    display: block;
    text-align: center;
    font-size: 0.9em;
    margin: 5em auto 0;
  }
}
