.Notfound {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-weight: 500;
    font-size: 2.5rem;
  }

  a {
    color: #000;
    text-decoration: underline;
    font-weight: 500;
    font-size: 1.5rem;
  }
}
