.ActivityCard {
  background: #ffffff;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 15px;
  width: 80%;
  margin: auto;
  padding: 2em 2em;
  line-height: 27px;
  box-sizing: border-box;
  color: #364049;
  // text-align: center;
  font-weight: bold;
  position: relative;
  top: -4.5em;
  margin-bottom: -4em;

  a {
    text-decoration: none;
  }

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
}

.HotelDisplayCards {
  width: 85%;
  margin: 2em auto 2em;
  display: grid;
  gap: 2em;
  grid-template-columns: repeat(3, 1fr);

  .container {
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    // font-family: "Nunito", sans-serif;
    // width: 210px;
    border-radius: 7px;

    .hotelPreview {
      min-width: 230px;
      background-size: cover;
      background-position: center;
      border-top-right-radius: 7px;
      border-top-left-radius: 7px;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
      background-repeat: no-repeat;
    }

    .content {
      font-size: 14px;
      padding: 15px 10px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      h3,
      h4 {
        font-weight: 400;
        text-align: center;
        margin: 0;
      }

      h4 {
        font-weight: 600;
      }

      .btn {
        background-color: #a0f8c0;
        border-radius: 100px;
        box-shadow:
          rgba(44, 187, 99, 0.2) 0 -25px 18px -14px inset,
          rgba(44, 187, 99, 0.15) 0 1px 2px,
          rgba(44, 187, 99, 0.15) 0 2px 4px,
          rgba(44, 187, 99, 0.15) 0 4px 8px,
          rgba(44, 187, 99, 0.15) 0 8px 16px,
          rgba(44, 187, 99, 0.15) 0 16px 32px;
        color: green;
        cursor: pointer;
        display: block;
        // font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto,
        //   sans-serif;
        padding: 7px 20px;
        text-align: center;
        text-decoration: none;
        transition: all 250ms;
        border: 0;
        font-size: 16px;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
      }

      .btn:hover {
        box-shadow:
          rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset,
          rgba(44, 187, 99, 0.25) 0 1px 2px,
          rgba(44, 187, 99, 0.25) 0 2px 4px,
          rgba(44, 187, 99, 0.25) 0 4px 8px,
          rgba(44, 187, 99, 0.25) 0 8px 16px,
          rgba(44, 187, 99, 0.25) 0 16px 32px;
        transform: scale(1.05) rotate(-1deg);
      }
    }
  }
}

@media (max-width: 850px) {
  .HotelDisplayCards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 550px) {
  .HotelDisplayCards {
    grid-template-columns: repeat(1, 1fr);
  }
}
